<template>
    <div class="home">
        <van-nav-bar class="navbar" title="许可证信息填写" :right-text="softName" />
        <van-notice-bar wrapable :scrollable="false" text="提示：请使用和订阅支付一致的手机号码登陆下载许可证，如需帮助，请联系客服：17786083608" />
        <van-form @submit="onSubmit">
            <van-field class="myvanCell machineStyle" v-model="machineCode" name="机器码" label="机器码" :readonly="true"
                type="textarea" rows="1" />

            <div class="myvanCell">
                <van-field v-model="phoneNum" name="手机号码" label="手机号码" :required="true"
                    :rules="[{ pattern, message: '请输入正确的手机号' }]" @input="usernameInput()">
                    <template #button>
                        <van-button size="small" type="primary" :disabled="disable" @click.prevent="sendCode">{{ yzm
                            }}</van-button>
                    </template>
                </van-field>
                <van-field v-model="sms" center clearable label="短信验证码" placeholder="请输入短信验证码">
                </van-field>
            </div>
            <!-- <van-field v-model="name" name="姓名" label="姓名" :required="true" :rules="[{ required: true }]" /> -->
            <!-- <van-field v-model="company" name="单位" label="单位" :required="true" :rules="[{ required: true }]" /> -->
            <!-- <van-field v-model="inviteCode" name="邀请码(选填)" label="邀请码(选填)" /> -->
            <div class="myvanCell">
                <van-field v-model="des" rows="4" autosize label="备注" type="textarea" maxlength="50" show-word-limit />
            </div>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit" style="margin-bottom: 10px;">选择产品</van-button>
                <!-- <van-button round block type="info" @click="rewrite">重新填写</van-button> -->
            </div>
        </van-form>
    </div>
</template>

<script>
import { getUserIdByPhone, getLicensesByPhone } from "@/assets/API/pay.js";
import {
    SendVerCode,
} from "@/assets/API/login";
import "amfe-flexible/index";
import { Notify } from 'vant';
import { CheckVerCode } from "../../assets/API/login";
export default {
    name: "Home",
    data() {
        return {
            softName: '',
            urlData: {},
            machineCode: '',
            phoneNum: '',
            name: '',
            company: '',
            inviteCode: '',
            des: '',
            sms: '',
            pattern: /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[0-9])\d{8}$/,
            yzm: "获取验证码",
            totalTime: 60,
            disable: true,
        };
    },
    mounted() {
        this.urlData = this.$route.query;
        this.machineCode = this.$route.query.id;
        if (this.$route.query.phoneNum || this.$route.query.des) {
            this.$route.query.phoneNum = '';
            this.$route.query.des = '';
        }
        console.log(this.urlData, 'sss');
        if (this.urlData.pid == undefined) {
            // this.xkShow = false;
            // this.phone = this.urls.phone;
            // this.exhibit = false;
            // this.querys();
        } else {
            if (this.urlData.pid == 1) {
                if (this.urlData.type == 2) {
                    this.softName = 'SummitMapTrial'
                } else {
                    this.softName = "SummitMap";
                }
            } else if (this.urlData.pid == 2) {
                if (this.urlData.type == 2) {
                    this.softName = 'SummitSolidTrial'
                } else {
                    this.softName = "SummitSolid";
                }
            } else if (this.urlData.pid == 4) {
                this.softName = "SummitLab";
            } else if (this.urlData.pid == 5) {
                this.softName = "SummitMapHUBEI";
            } else if (this.urlData.pid == 6) {
                this.softName = "SummitLab";
            } else if (this.urlData.pid == 7) {
                this.softName = "SummitSolidSGJ";
            } else if (this.urlData.pid == 8) {
                this.softName = "SummitTerra";
            } else if (this.urlData.pid == 10) {
                this.softName = "SummitViewPro";
            }
        }
    },
    // components: {
    //     [Notify.Component.name]: Notify.Component,
    // },
    watch: {

    },
    methods: {
        onClickLeft() {
            // 返回列表选择
            this.$router.push({
                name: "ApplyHome", path: "/ApplyHome", query: this.$route.query
            });
        },
        onSubmit() {
            // if(this.phoneNum != null){

            // }
            // 确认验证码是否正确
            if (!this.sms) {
                Notify({ type: 'danger', message: '请填写验证码' });
                return;
            }
            CheckVerCode(this.phoneNum, this.sms).then(res => {
                if (res.code != 200) {
                    Notify({ type: 'danger', message: res.msg });
                } else {
                    let query = {
                        id: this.machineCode,
                        pid: this.urlData.pid,
                        type: this.urlData.type,
                        phoneNum: this.phoneNum,
                        // name: this.name,
                        // company: this.company,
                        // inviteCode: this.inviteCode,
                        des: this.des,
                    };
                    this.$router.push({
                        name: "ApplyList", path: "/ApplyList", query: query
                    });
                }
            });
        },
        rewrite() {
            this.phoneNum = '';
            this.name = '';
            this.company = '';
            this.inviteCode = '';
            this.des = '';
        },
        usernameInput() {
            const re = /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/;
            if (re.test(this.phoneNum) && this.phoneNum * 1 != "NaN") {
                if (this.totalTime < 60) {
                    this.disable = true;
                }
                this.disable = false;
            } else {
                this.disable = true;
            }
        },
        sendCode() {
            this.disable = true;
            SendVerCode(this.phoneNum).then(res => {
                if (res.code == 200) {
                    // 发送成功
                    Notify({ type: 'success', message: '发送成功!' });
                    this.yzm = this.totalTime + "s后重新发送";
                    const clock = setInterval(() => {
                        this.yzm = this.totalTime-- + "s后重新发送";
                        if (this.totalTime < 0) {
                            clearInterval(clock);
                            this.disable = false;
                            this.yzm = "重新发送验证码";
                            this.totalTime = 60;
                        }
                    }, 1000);
                } else {
                    // 发送失败
                    Notify({ type: 'warning', message: res.msg });
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.home {}

.navbar {
    ::v-deep .van-nav-bar__right {
        .van-nav-bar__text {
            color: #808080;
        }
    }
}

.van-form {
    background-color: rgb(229, 229, 229, 0.5);
    padding-top: 5px;

}

.myvanCell {
    border-radius: 10px;
    margin: 7px;
    margin-top: 0px;
    overflow: hidden;
    width: auto;
}

.machineStyle {
    display: block;

    ::v-deep .van-field__control {
        min-height: auto;
        background-color: rgba(43, 133, 229, 0.1);
        text-align: center;
        margin: 5px 0;
    }

}
</style>